<template>
  <div class="main-container">
    <div class="candidate-filter-header-text">
      <a-button
        style="
          cursor: default;
          color: #4c5155;
          font-family: 'Poppins';
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        "
        type="link"
        >Manage Columns</a-button
      >
      <a-button type="link" style="color: #e5816a">Reset Changes</a-button>
    </div>
    <div class="child-tag-section" v-for="item in masterList" :key="item.id">
      <div v-if="item.isVisible">
        <p class="title-tag">{{ item.name }}</p>
        <a-checkable-tag
          v-for="child in item.children"
          :key="child.id"
          class="check-tag"
          v-model:checked="child.isSelecetd"
          >{{ child.name }}
        </a-checkable-tag>
      </div>
    </div>
    <div class="footer">
      <a-button
        key="submit"
        style="
          background: RGB(228, 129, 106);
          color: #ffffff;
          border-radius: 15px;
          font-size: $font_size_12;
          font-family: Poppins;
          font-weight: 500;
        "
        @click="UpdateColumns"
        >Save
      </a-button>
    </div>
  </div>
</template>
<script>
export default {
  props: ["masterList"],

  setup(props, context) {
    function UpdateColumns() {
      context.emit("updateColumns", props.masterList, false);
    }
    return {
      UpdateColumns,
    };
  },
};
</script>
<style scoped>
@import "../styles/manageColumns.scss";
</style>
