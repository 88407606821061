<template>
  <div>
    <div class="meeting-listing-main-wrapper">
      <div class="meeting-listing-table-with-select-wrapper">
        <div class="meeting-listing-select-section-with-search">
          <div class="meeting-listing-select-section">
            <div v-for="tag in tags" :key="tag.id" style="padding-left: 10px">
              <a-checkable-tag
                :checked="selectedTags.indexOf(tag.id) > -1"
                @change="(checked) => handleChange(tag.id, checked)"
              >
                {{ tag.title }}
              </a-checkable-tag>
            </div>
          </div>

          <div class="meeting-listing-search-and-filter-wrapper">
            <div class="meeting-profile-filter-wrapper">
              <a-input-search v-model:value="value" placeholder="search" />
            </div>
            <div class="meeting-profile-search-wrapper">
              <a-button @click="showVacancyFilters">
                <FunnelPlotOutlined /><span class="meeting-listing-filter-text"
                  >Filter</span
                >
                <DownOutlined
              /></a-button>
            </div>
          </div>
        </div>

        <div class="meeting-listing-table-wrapper">
          <div class="meeting-listing-table-select-section">
            <div
              v-for="type in types"
              :key="type.id"
              style="padding-left: 10px"
            >
              <a-checkable-tag
                :checked="selectedtype.indexOf(type.id) > -1"
                @change="(checked) => handleTableChange(type.id, checked)"
              >
                {{ type.title }}
              </a-checkable-tag>
            </div>
          </div>
          <div class="meeting-listing-table">
            <a-table
              :columns="vacancyColumnData"
              :data-source="vacancyData"
              :row-selection="rowSelection"
              :pagination="false"
              :rowKey="(record) => record.vacancy.vacancyId"
            >
              <template #vacancy="{ text }">
                <div
                  class="meeting-title-in-table"
                  @click="vacancyProfile(text.id)"
                >
                  {{ text.vacancyName }}
                </div>
                <u class="meeting-id-in-table">{{ text.vacancyId }}</u>
                <BarChartOutlined
                  class="meeting-taxonomy-in-table"
                  @click="showTaxonomy()"
                />
              </template>

              <template #company="{ text }">
                <div style="display: flex">
                  <div v-if="text.logo" style="margin: 5px 5px 0px 0px">
                    <img src="{{text.logo}}" alt="Candidate image" />
                  </div>
                  <div v-else style="margin: 5px 5px 0px 0px">
                    <a-avatar>
                      <template #icon><UserOutlined /></template>
                    </a-avatar>
                  </div>
                  <div>
                    <div class="meeting-title-in-table">{{ text.name }}</div>
                    <u class="meeting-id-in-table">{{ text.companyId }}</u>
                    <BarChartOutlined
                      class="meeting-taxonomy-in-table"
                      @click="showCompanyTaxonomy()"
                    />
                  </div>
                </div>
              </template>
              <template #leadCic="{ text }">
                <div class="meeting-followUp-in-table">{{ text }}</div>
              </template>
              <template #dateDiffer="{ text }">
                <div class="meeting-title-in-table">{{ text }}</div>
              </template>
              <template #trackerType="{ text }">
                <div class="meeting-title-in-table">{{ text }}</div>
              </template>
              <template #followUp="{ text }">
                <div class="meeting-followUp-in-table">{{ text }}</div>
              </template>
              <template #date="{ text }">
                <div class="meeting-followUp-in-table">{{ text }}</div>
              </template>
              <template #remark="{ text }">
                <div class="meeting-followUp-in-table">{{ text }}</div>
              </template>
              <template #customTitle>
                <a-button
                  @click="showColumnManager"
                  type="link"
                  style="color: #4c5155"
                >
                  <MenuOutlined />
                </a-button>
              </template>
            </a-table>
            <div class="meeting-listing-view-more-wrapper">
              <div @click="viewMoreListing">
                <PlusCircleOutlined />
                <span class="meeting-list-view-more-text">View more</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <a-modal
          class="filter-modal"
          title=""
          width="500px"
          v-model:visible="vacancyModalVisibility"
          :closable="false"
          centered
          footer=""
          destroyOnClose
        >
          <VacancyWidget />
        </a-modal>
      </div>
      <div>
        <a-modal
          class="filter-modal"
          title=""
          width="500px"
          v-model:visible="companyModalVisibility"
          :closable="false"
          centered
          footer=""
          destroyOnClose
        >
          <CompanyWidget />
        </a-modal>
      </div>
      <div>
        <a-modal
          class="column-modal"
          title=""
          v-model:visible="manageColumnsModalVisibility"
          :closable="false"
          centered
          @ok="columnManagerModalOk"
          width="500px"
        >
          <div>
            <div style="width=80%">
              <manageColumnComponent
                :masterList="columnData"
                @updateColumns="updateColumns"
              />
            </div>
          </div>
          <template #footer>
            <a-button> </a-button>
          </template>
        </a-modal>
        <a-modal
          class="filter-modal"
          title=""
          v-model:visible="vacancyFilterVisibility"
          :closable="false"
          centered
          @ok="vacancyFilterModalOk"
          width="1200px"
        >
          <div>
            <div style="width=80%">
              <Filter />
            </div>
          </div>
          <template #footer>
            <a-button
              type="link"
              style="
                color: rgb(76, 81, 85);
                font-size: $font_size_12;
                font-family: Poppins;
                font-weight: 500;
              "
              key="back"
              @click="vacancyFilterModalCancel"
              >Cancel</a-button
            >
            <a-button
              key="submit"
              style="
                background: RGB(228, 129, 106);
                color: #ffffff;
                border-radius: 15px;
                font-size: $font_size_12;
                font-family: Poppins;
                font-weight: 500;
              "
              @click="vacancyFilterModalOk"
              >Apply Filter</a-button
            >
          </template>
        </a-modal>
      </div>
      <div class="action-bar">
        <RightSideBar />
      </div>
    </div>
  </div>
</template>
<script>
import Services from "@/services/apis";
import {
  FunnelPlotOutlined,
  DownOutlined,
  PlusCircleOutlined,
  UserOutlined,
  BarChartOutlined,
  MenuOutlined,
} from "@ant-design/icons-vue";

import { ref, reactive, toRefs, onMounted } from "vue";
// import VacancyWidget from "./widget.vue";
// import CompanyWidget from "./companyWidget.vue";
import { useRouter } from "vue-router";

import RightSideBar from "./actionRightSideBar.vue";
import manageColumnComponent from "../../common/components/manageColumns.vue";
// import Filter from "./Filter.vue";

export default {
  components: {
    
    FunnelPlotOutlined,
    DownOutlined,
    MenuOutlined,
    RightSideBar,
    PlusCircleOutlined,
    BarChartOutlined,
    UserOutlined,
    manageColumnComponent,
    
  },
  setup() {
    // onMounted(() => {
    //   fetchVacancyListing();
    // });
    const router = useRouter();
    const vacancyProfile = (id) => {
      router.push(`/vacancies/${id}`);
    };

    let currentPageNumber = 0;
    const vacancyModalVisibility = ref(false);
    const companyModalVisibility = ref(false);
    // let widget_id = ref("");

    const showTaxonomy = () => {
      // widget_id.value = id;
      vacancyModalVisibility.value = true;
    };

    const showCompanyTaxonomy = () => {
      // widget_id.value = id;
      companyModalVisibility.value = true;
    };
    let manageColumnsModalVisibility = ref(false);
    const columnManagerModalOk = (e) => {
      manageColumnsModalVisibility.value = false;
    };

    const vacancyTimeline = reactive({
      tags: [
        { id: "TODAY", title: "Today" },
        { id: "3D", title: "3D" },
        { id: "W", title: "W" },
        { id: "M1", title: "M" },
        { id: "Q1", title: "Q1" },
        { id: "Q2", title: "Q2" },
        { id: "Q3", title: "Q3" },
        { id: "Q4", title: "Q4" },
        { id: "Y2", title: "Y" },
        { id: "Y1", title: "Y-1" },
        { id: "Tm", title: "Tm" },
        { id: "N3d", title: "N3d" },
        { id: "NM", title: "NM" },
        { id: "NY", title: "NY" },
      ],
      selectedTags: ["TODAY"],
    });
    let vacancyData = reactive([]);
    const vacancyFilterVisibility = ref(false);
    const showVacancyFilters = () => {
      vacancyFilterVisibility.value = true;
    };
    const vacancyFilterModalOk = (e) => {
      vacancyFilterVisibility.value = false;
    };

    const vacancyFilterModalCancel = () => {
      vacancyFilterVisibility.value = false;
    };

    let vacancyColumnData = reactive([
      {
        title: "Track ID",
        dataIndex: "trackId",
        key: "trackId",
        slots: { customRender: "trackId" },
      },
      {
        title: "Accounts",
        dataIndex: "company",
        key: "company",
        slots: { customRender: "company" },
      },
      {
        title: "Lead CIC",
        dataIndex: "leadcic",
        key: "leadcic",
        slots: { customRender: "leadcic" },
        align: "center",
      },
      {
        title: "Date Differ",
        dataIndex: "dataDiffer",
        key: "dataDiffer",
        slots: { customRender: "dataDiffer" },
      },
      {
        title: "Tracker Type",
        dataIndex: "trackerType",
        key: "trackerType",
        slots: { customRender: "trackerType" },
      },
      {
        title: "Follow Up Date",
        dataIndex: "followUp",
        key: "followUp",
        slots: { customRender: "followUp" },
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        slots: { customRender: "date" },
      },
      {
        title: "Remark",
        dataIndex: "remark",
        key: "remark",
        slots: { customRender: "remark" },
      },
      {
        colSpan: 1,
        width: 0,
        key: "manageColumns",
        slots: { title: "customTitle" },
      },
    ]);
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        nameLists = [];
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          "selectedRows: ",
          selectedRows
        );
      },
    };
    const handleChange = (tag, checked) => {
      console.log(tag);
      const { selectedTags, tags } = vacancyTimeline;
      const nextSelectedTags = checked
        ? tags.filter((t) => t.id === tag)
        : selectedTags.filter((t) => t.id !== tag);
      console.log("nextSelectedTags", nextSelectedTags);
      vacancyTimeline.selectedTags = nextSelectedTags[0].id;
    };

    const handleTableChange = (type, checked) => {
      console.log(type);
      const { selectedtype, types } = vacancyTableTimeline;
      const nextSelectedTypes = checked
        ? types.filter((t) => t.id === type)
        : selectedtype.filter((t) => t.id !== type);
      console.log("nextSelectedTypes", nextSelectedTypes);
      vacancyTableTimeline.selectedtype = nextSelectedTypes[0].id;
    };

    const viewMoreListing = () => {
      currentPageNumber++;
    //   fetchVacancyListing();
    };

    function updateColumns(masterList, status) {
      columnData = masterList;
      manageColumnsModalVisibility.value = status;
      let newColumnData = [];
      candidateListingColumns.map((column, ind) => {
        columnData.map((parent) => {
          let filterData = parent.children.filter(
            (obj) => obj.isSelecetd === true && obj.id === column.dataIndex
          );
          if (filterData.length > 0) {
            newColumnData.push(column);
          }
        });
      });
      console.log("newColumnData :>> ", newColumnData);
      Object.assign(listedCandidateColumns, newColumnData);
    }
    const showColumnManager = () => {
      manageColumnsModalVisibility.value = true;
    };
    return {
      ...toRefs(vacancyTimeline),
      handleChange,
      handleTableChange,
      vacancyColumnData,
      vacancyData,
      rowSelection,
      viewMoreListing,
      currentPageNumber,
      vacancyModalVisibility,
      // widget_id,
      showTaxonomy,
      vacancyProfile,
      companyModalVisibility,
      showCompanyTaxonomy,
      manageColumnsModalVisibility,
      columnManagerModalOk,
      updateColumns,
      showColumnManager,
      vacancyFilterVisibility,
      showVacancyFilters,
      vacancyFilterModalOk,
      vacancyFilterModalCancel,
    };
  },
};
</script>
<style lang="scss">
@import '../styles/meetingList.scss';
</style>